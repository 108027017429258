@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  width: 100vw;

}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  background: url("./assets/img/mwebbg.png");

  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  max-width: 100%;
  height: auto;
  width: auto\9;
}

@media only screen and (min-width:1200px) {
  body {
    background: url("/src/assets/img/webbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    max-width: 100%;
    height: auto;
    width: auto\9;
  }
}

input,
button {
  font-family: 'Poppins', sans-serif;
}